<template>
    <div>
      <b-breadcrumb class="mb-2 ">
        <b-breadcrumb-item to="/orders/business">Packages</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link
          :to="{
            name: 'business-commande',
            params: { id: this.$route.query.packageId } 
           
          }"
          >Commandes</router-link
        >
      </b-breadcrumb-item>
            <b-breadcrumb-item>
             <router-link :to="{ name: 'admin-orders-business-recurring-details', params: { id: this.$route.params.id }, query: {packageId: this.$route.query.packageId} }">Détails</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>Résiliation</b-breadcrumb-item>
    </b-breadcrumb>
      <!-- Order assignment Modal  -->
      <b-card title="Résiliation des employés">
        <b-row>
          <b-col>
            <validation-observer
              #default="{}"
              ref="orderAssignmentForm"
              tag="form"
            >
              <!-- Form -->
              <b-form
                v-for="(form, index) in forms"
                :key="index"
                class="p-2"
                autocomplete="off"
              >
                <b-form-group
                  label="Choisissez un employé"
                  label-for="role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="pro"
                    rules="required"
                  >
                    <b-form-select
                      v-if="employeeAffectedOptions.length === 1 && employeeAffectedOptions[0].disabled"
                      id="employee_id"
                      v-model="form.employee_id"
                      :options="employeeAffectedOptions"
                    />
                    <v-select
                      v-else
                      id="role"
                      v-model="form.id"
                      :reduce="(employee) => employee.affectation_id"
                      label="full_name"
                      :options="employeeAffectedOptions"
                    >
                      <template v-slot:no-options>
                        <b-spinner
                          v-if="isGetServiceEmployeesActionLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        />
                      </template>
                      <template
                        #option="{
                          id,
                          profile_image,
                          full_name,
                          age,
                          marital_status,
                          address,
                          nationality,
                          degree,
                          mtn_number,
                          flooz_number,
                          employee_salary,
                          salary_pretention,
                          employee
                        }"
                      >
                        <b-row>
                          <b-col
                            md="3"
                            class="offset-md-3"
                          >
                            <b-avatar
                              size="90px"
                              rounded
                              :src="employee.profile_image"
                            />
                          </b-col>
                        </b-row>
                        <br>
                        <b-row>
                          <b-col md="9">
                            <b-row>
                              <b-col>
                                <span class="text-white">
                                  Nom & Prénom:
                                  <strong class="text-info"> {{ employee.full_name }} </strong>
                                </span>
                              </b-col>
                              <br>
                              <b-col>
                                <span class="text-white">
                                  Age:
                                  <strong class="text-info"> {{ employee.age }} ans </strong>
                                </span>
                              </b-col>
                              <br>
                            </b-row>
  
                            <b-row class="">
                              <b-col>
                                <span class="text-white">
                                  Situation matrimonial:<strong class="text-info">
                                    {{ employee.marital_status }}
                                  </strong>
                                </span>
                              </b-col>
                              <br>
                              <b-col>
                                <span class="text-white">
                                  Quartier:
                                  <strong class="text-info"> {{ employee.address }}</strong>
                                </span>
                              </b-col>
                              <br>
                            </b-row>
                            <b-row class="">
                              <b-col>
                                <span class="text-white">
                                  Nationnalité:
                                  <strong class="text-info">
                                    {{ employee.nationality }}
                                  </strong>
                                </span>
                              </b-col>
                              <br>
                              <b-col>
                                <span class="text-white">
                                  Diplôme:
                                  <strong class="text-info"> {{ employee.degree }}</strong>
                                </span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <br>
                              <b-col>
                                <span class="text-white">
                                  Numéro MTN momo :
                                  <strong class="text-info">
                                    {{ employee.mtn_number != null ? employee.mtn_number : "Non renseigné" }}
                                  </strong>
                                </span>
                              </b-col>
                              <br>
                              <b-col>
                                <span class="text-white">
                                  Numéro Flooz momo :
                                  <strong class="text-info">
                                    {{
                                      employee.flooz_number != null ? employee.flooz_number : "Non renseigné"
                                    }}
                                  </strong>
                                </span>
                              </b-col>
                              <br>
  
                              <b-col>
                                <span
                                  class="text-white"
                                >Prétention salariale
                                  <strong class="text-info">
                                    {{
                                      employee.employee_salary != null
                                        ? employee.salary_pretention
                                        : "Non renseigné"
                                    }}</strong></span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <hr>
                        <br>
                      </template>
                    </v-select>
  
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
  
                <b-form-group
                  label="Type de résiliation"
                  label-for="type"
                >
                <validation-provider
                    #default="{ errors }"
                    name="resiliationType"
                    rules="required"
                  >
                  <v-select
                    v-model="form.type"
                    :options="resiliationTypeOptions"
                    label="label"
                    :reduce="(item) => item.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
      
                <b-form-group
                  label="Raison"
                  label-for="textarea-default"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="reason"
                    rules="required"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="form.reason"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Entrez la raison de la résiliation"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              <b-form-group
                  label="Date de la resiliation"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date de resiliation"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="form.date"
                      class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
  
                <!-- Reapet form -->
                <div class="d-flex mt-2">
                  <b-button
                    type="reset"
                    variant="danger"
                    :disabled="forms.length == 1"
                    class="mb-2"
                    @click="removeItem(index)"
                  >
                    Suprimer
                  </b-button>
                </div>
              </b-form>
              <b-row>
                <!-- Reapet form -->
  
                <b-col
                  cols="12"
                  md="8"
                  class="mb-4 d-flex align-items-center justify-content-start mb-2"
                >
                  <b-button
                    variant="primary"
                    class="ml-2"
                    @click="repeateAgain"
                  >
                    Autre résiliation
                  </b-button>
                </b-col>
                <b-col
                  class="d-flex align-items-center justify-content-end mb-2"
                  md="4"
                  @click="applyResiliationAction()"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    :disabled="isOrderAssignmentLoading"
                  >
                    {{ !isOrderAssignmentLoading ? "Soumettre" : "Chargement..." }}
                  </b-button>
                </b-col>
              </b-row>
  
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BTab,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BTableLite,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BSpinner,
    BFormSelect,
    BForm,
    BCard,
    BTabs,
    BFormGroup,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
  } from 'bootstrap-vue'
  import { avatarText } from '@core/utils/filter'
  import vSelect from 'vue-select'
  import { mapActions, mapGetters } from 'vuex'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import { VueGoodTable } from 'vue-good-table'
  import flatPickr from "vue-flatpickr-component"
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import store from '@/store/index'
  
  export default {
    components: {
      BSpinner,
      BTab,
      BRow,
      BCol,
      BFormInput,
      BCard,
      BTableLite,
      BButton,
      BTable,
      BMedia,
      BTabs,
      BDropdown,
      BDropdownItem,
      BAvatar,
      BBadge,
      BPagination,
      BTooltip,
      BFormSelect,
      BForm,
      BFormGroup,
      BFormTextarea,
      ValidationProvider,
      ValidationObserver,
      VueGoodTable,
      vSelect,
      BBreadcrumb,
      BBreadcrumbItem,
      flatPickr,
    },
  
    data() {
      return {
        /* eslint-disable global-require */
        isGetServiceEmployeesActionLoading: true,
        employeeAffectedOptions: [],
        rowsDirectOrdersHaveNotProposal: [],
        resiliationTypeOptions: [
      {
          label: "Résiliation normal",
          value: 1,
        },
        {
          label: "Incompétence de l'employé",
          value: 0,
        },
      ],
        columns: [
          {
            label: 'Client',
            field: 'user',
            filterOptions: {
              enabled: true,
              placeholder: 'Rechercher...',
              filterFn(data, filterString) {
                return data.full_name.toLowerCase().includes(filterString.toLowerCase())
              },
            },
          },
          {
            label: 'Service demandé',
            field: 'recurring_service',
            filterOptions: {
              enabled: true,
              placeholder: 'Rechercher...',
              filterFn(data, filterString) {
                return data.name.toLowerCase().includes(filterString.toLowerCase())
              },
            },
          },
          {
            label: 'Budget',
            field: 'budget',
          },
          {
            label: 'Lieu',
            field: 'address',
            filterOptions: {
              enabled: true,
              placeholder: 'Rechercher...',
              filterFn(data, filterString) {
                return data.toLowerCase().includes(filterString.toLowerCase())
              },
            },
          },
          {
            label: 'Traitée par',
            field: 'assign_to',
            filterOptions: {
              enabled: true,
              placeholder: 'Rechercher...',
              filterFn(data, filterString) {
                return data.full_name.toLowerCase().includes(filterString.toLowerCase())
              },
            },
          },
          {
            label: 'Action',
            field: 'action',
          },
        ],
  
        isOrderAssignmentLoading: false,
        forms: [
          {
            id: '',
            type: '',
            reason: '',
            date: '',
          },
        ],
        payload: {
          actifAssignation: [],
        },
        employeeRecommendationId: '',
        updatePayload: {
          employee_id: '',
          comment: '',
          employee_salary: '',
        },
  
        items: [],
        currentOrder: {},
        isLoadingEmployeesRecommended: false,
        total: '',
      }
    },
  
    computed: {
      ...mapGetters('orders', [
        'getDirectOrdersHaveNotProposal',
        'getDirectOrdersWhoNeedRecommandation',
      ]),
      ...mapGetters(['isDirectOrdersWhoNeedRecommandationLoading']),
      ...mapGetters('auth', ['getUser']),
      ...mapGetters('roles', ['getBusinessManagersList']),
  
      metaDataHaveNotProposal() {
        const meta = {
          prev_page_url: '',
          next_page_url: '',
          current_page: '',
          last_page: '',
          current_page_url: '',
        }
        if (this.getDirectOrdersWhoNeedRecommandation != null) {
          meta.prev_page_url = this.getDirectOrdersWhoNeedRecommandation.prev_page_url
          meta.next_page_url = this.getDirectOrdersWhoNeedRecommandation.next_page_url
          meta.current_page = this.getDirectOrdersWhoNeedRecommandation.current_page
          meta.last_page = this.getDirectOrdersWhoNeedRecommandation.last_page
          meta.current_page_url = `${this.getDirectOrdersWhoNeedRecommandation.path}?page=${this.getDirectOrdersWhoNeedRecommandation.current_page}`
        }
        return meta
      },
    },
  
    watch: {
      isDirectOrdersWhoNeedRecommandationLoading(val) {
        if (val === false) {
          this.rowsDirectOrdersHaveNotProposal = this.getDirectOrdersHaveNotProposal
          this.total = this.rowsDirectOrdersHaveNotProposal.length
        }
      },
    },
  
    created() {
        this.applyGetBusinessOrder(this.$route.params.id);
        this.applyGetAffectedEmployeesAction()
        console.log(this.item);
        
        /* this.applyGetServiceEmployeesAction(this.$route.query.directOrderId)
        this.getDirectOrdersHaveNotProposal != null
            ? (this.rowsDirectOrdersHaveNotProposal = this.getDirectOrdersHaveNotProposal)
            : null */
    },
  
    mounted() {},
  
    methods: {
      ...mapActions('services', ['getServiceEmployeesAction']),
      ...mapActions('orders', [
      'getBusinessOrderAction',
        "getAffectedEmployeesAction",
        "getBusinessOrdersAction",
        "makeBusinessEmployeeDeploymentAction",
        'makeEmployeeResiliationAction',
        "employeeResiliationAction",
        'assignAnOrderToBusinessManagerAction',
        'loadMoreDirectOrdersWhoNeedRecommandationAction',
        'getDirectOrdersAssignedToBusinessManagerAction',
        'makeEmployeeRecommandationAction',
        'modifyRecommandationAction',
        'getRecommendedEmployeesAction',
        'getDirectOrderProposedEmployeesAction',
        'modifyRecommandationAction',
      ]),
      ...mapActions('professionals', ['deleteEmployeeRecommendationAction']),

      applyGetBusinessOrder(id) {

this.getBusinessOrderAction(id)
    .then((response) => {
        this.isPageLoading = false;
        console.log("Orderssss", response.data);
        this.directOrder = response.data;
        /* this.applyGetRecommendedEmployeesAction(this.$route.params.id); */
        this.directOrder = this.resolveStatusText(this.directOrder);
        console.log("Orderssss", this.directOrder);
        this.directOrder = this.resolveStatusText(this.directOrder);
        // this.applyGetDirectOrdersSalaryPaymentByOrderAction(
        // this.directOrder.id,
        // )
    })
    .catch((error) => {
        console.log(error);
        this.isPageLoading = false;
        this.isError = true;
        this.errorMessage = Object.values(error.response.data)[0];
    });
// }
},

applyGetAffectedEmployeesAction() {
this.isProposedEmployeesLoading = true;

this.getAffectedEmployeesAction(this.$route.params.id)
    .then((response) => {
        this.propositions = response.data;

        this.isProposedEmployeesLoading = false;

        if (response.data.length === 0) {
            this.employeeAffectedOptions.push({
                value: "",
                disabled: true,
                text: "Aucun employé n'est effectué",
            });
        } else {
            response.data.forEach((element) => {
                this.employeeAffectedOptions.push({
                    employee_salary: element.employee_salary,
                    full_name: element.employee.full_name,
                    id: element.employee.id,
                    affectation_id: element.id,
                    employee_desired_salary: element.employee_salary,
                    employee: element.employee,

                });
            })
        }
    })
    .catch((error) => {
        this.isProposedEmployeesLoading = false;
        this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
            },
        }, {
            position: 'top-center',
        }, )
    });
},

applyGetBusinessOrders() {
this.isReLoadDataRecurringOrders = true
this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', true)

this.getBusinessOrdersAction()
    .then(response => {
        this.isReLoadDataRecurringOrders = false
        this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
    })
    .catch(error => {
        this.isReLoadDataRecurringOrders = false
        this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
    })
},

repeateAgain() {
        this.$refs.orderAssignmentForm.validate().then(success => {
          if (success) {
            this.forms.push({
              id: '',
              type: '',
              reason: '',
              date: '',
            })
          } else {
            reject()
          }
        })
      },
  
      removeItem(index) {
        this.forms.splice(index, 1)
      },

applyResiliationAction() {
        this.$refs.orderAssignmentForm.validate().then(success => {
          if (success) {
            this.payload.actifAssignation = this.forms
            this.isOrderAssignmentLoading = true
            this.makeEmployeeResiliationAction({ payload: this.payload, orderId: this.$route.params.id })
              .then(async response => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.isOrderAssignmentLoading = false
                this.$router.push(
                  `/orders/business/recurring/${this.$route.params.id}/details?packageId=${this.$route.query.packageId}`,
                )
                this.applyGetBusinessOrders()
              })
              .catch(error => {
                this.isOrderAssignmentLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          }
        })
      },
  
      applyLoadMoreDirectOrdersWhoNeedRecommandationAction(url) {
        this.$store.commit('SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING', true)
        this.rowsDirectOrdersHaveNotProposal = []
        this.loadMoreDirectOrdersWhoNeedRecommandationAction(url)
  
          .then(() => {
            this.$store.commit(
              'SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING',
              false,
            )
          })
          .catch(() => {
            this.$store.commit(
              'SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING',
              false,
            )
          })
      },
      
      showEmployeeRecommendedModal(data) {
        this.applyGetEmployeesRecommendedByOrder(data.id)
        this.$bvModal.show('modal-oder-have-not-proposal-recommended-employee')
        this.currentOrder = data
        this.applyGetServiceEmployeesAction(this.currentOrder)
      },
  
      showUpdateRecommandationModal(item) {
        this.$bvModal.hide('modal-oder-have-not-proposal-recommended-employee')
        this.$bvModal.show('modal-update-order-have-not-recommandation')
        this.updatePayload.employee_salary = item.employee_salary
        this.updatePayload.comment = item.comment
        this.updatePayload.employee_id = item.employee_id
        this.employeeRecommendationId = item.id
      },
  
      showFeedbackModal(item) {
        this.$bvModal.show('modal-oder-have-not-proposal-show-feedback')
        this.currentOrder = item
        console.log(item)
      },
  
      applyDeleteEmployeeRecommendation(item) {
        this.$swal({
          title: 'Annuler',
          text: 'Êtes-vous sûr de vouloir supprimer cette Recommandation ?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Non',
          allowOutsideClick: false,
          confirmButtonText: 'Oui',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
          preConfirm: async () => {
            await this.deleteEmployeeRecommendationAction({
              id: item.direct_order_id,
              employeeRecommendationId: item.id,
            })
            // eslint-disable-next-line no-return-await
          },
        })
          .then(response => {
            if (response.value) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: 'Rcommandation supprimée',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-oder-have-not-proposal-recommended-employee')
              this.showEmployeeRecommendedModal(this.currentOrder)
              this.applyLoadMoreDirectOrdersWhoNeedRecommandationAction(
                this.metaDataHaveNotProposal.current_page_url,
              )
            }
          })
          .catch(error => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          })
      },
      applyGetEmployeesRecommendedByOrder(id) {
        this.isLoadingEmployeesRecommended = true
        this.getRecommendedEmployeesAction(id).then(response => {
          this.isLoadingEmployeesRecommended = false
          this.items = response.data
        })
      },
  
      applyGetServiceEmployeesAction(order) {
        this.isGetServiceEmployeesActionLoading = true
        this.currentOrder = order
        this.employeeOptions = []
        this.getServiceEmployeesAction(this.$route.query.recurringServiceId).then(
          response => {
            this.isGetServiceEmployeesActionLoading = false
            if (response.data.length === 0) {
              this.employeeOptions.push({
                value: '',
                disabled: true,
                text: "Pas d'employés formés qui fournissent ce service",
              })
            } else {
              response.data.forEach(element => {
                if (element.employee != null) {
                  this.employeeOptions.push(element.employee)
                }
              })
            }
          },
        )
      },
  
      applyUpdateRecommandationAction() {
        this.isOrderAssignmentLoading = true
        this.modifyRecommandationAction({
          orderId: this.currentOrder.id,
          employeeRecommendationId: this.employeeRecommendationId,
          payload: this.updatePayload,
        })
          .then(async response => {
            console.log('ipdate', response)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.isOrderAssignmentLoading = false
            this.hideModal(
              this.updatePayload,
              'modal-update-order-have-not-recommandation',
            )
            this.showEmployeeRecommendedModal(this.currentOrder)
          })
          .catch(error => {
            this.isOrderAssignmentLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.updatePayload = {
              employee_salary: '',
              comment: '',
              employee_id: '',
            }
          })
      },
      hideModal(data, modal) {
        data.employee_salary = ''
        data.employee_id = ''
        data.comment = ''
        this.$bvModal.hide(modal)
      },
     
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  </style>
  